input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white !important;
  color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

.MuiInputBase-input {
    text-align: center;
}


*::-webkit-scrollbar-thumb {
    background-color: transparent;
}


    .formHeader {
        margin-left: 40px !important;
        padding-right: 37px !important;
    }


@media (min-width: 376px)   {
    .formHeader {
        margin-left: 90px !important;
        padding-right: 90px !important;
    }
}
@media (min-width: 426px)   {
    .formHeader {
        margin-left: 70px !important;
        padding-right: 70px !important;
    }
}
@media (min-width: 2560px)   {
    .formHeader {
        margin-left: 70px !important;
        padding-right: 70px !important;
    }
}